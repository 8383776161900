import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Tab, Nav, Button, Modal } from "react-bootstrap";
import StaffOverviewBlocks from "../../components/general/StaffOverviewBlocks";
import HolidaysGroup from "./HolidaysGroup";
import AbsenceLeave from "./AbsenceLeave";
import StaffWorkLoads from "./StaffWorkLoads";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import AddStaffHoliday from "./AddStaffHoliday";

// import EditHoliday from "./EditHoliday";
function Holiday() {


  // Modal Popup
  const [showAddHoliday, setShowAddHoliday] = useState(false);
  const subModalAddHoliday = () => {
    setShowAddHoliday(!showAddHoliday);
    // fetchAllRecords();
  };



  return (
    <>
      <Layout pageTitleText="Holiday">
        <div className="holidaysWrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="tabsWrapper">
                <Tab.Container id="myTab" defaultActiveKey="holidaysTab">
                  <div className="tabsHeader">
                    <div className="holidayHeaderStuff">
                      <div className="actionButtons">
                        <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                          <Button
                            className="btn-sm btn-primary"
                            onClick={() => subModalAddHoliday()}
                          >
                            Add New Holiday
                          </Button>
                        </ProtectedComponent>
                      </div>
                    </div>

                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="holidaysTab">Holidays</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="archivedCandidates">
                          Absence & Leaves
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="staffWorkloads">
                          Staff Workloads
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <StaffOverviewBlocks />
                  <Tab.Content>
                    <Tab.Pane eventKey="holidaysTab">
                      <div className="holidaysTabsContent">
                        <HolidaysGroup />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="archivedCandidates">
                      <div className="holidaysTabsContent">
                        <AbsenceLeave />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="staffWorkloads">
                      <div className="holidaysTabsContent">
                        <StaffWorkLoads />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>


      </Layout>

      {/* Add Holiday */}
      <ProtectedComponent requiredPermissions={[permissions.ADD]}>
        <Modal
          show={showAddHoliday}
          onHide={subModalAddHoliday}
          className="ftcModalPopup medicineInventoryStock"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Holiday</Modal.Title>
          </Modal.Header>
          <Modal.Body className="rosterPopup">
            <AddStaffHoliday
              subModalAddHoliday={subModalAddHoliday}
            />
          </Modal.Body>
        </Modal>
      </ProtectedComponent>
      {/* {isHoliday && <ToastContainer />} */}
    </>
  );
}

export default Holiday;
