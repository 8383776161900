import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import {
  getRecords,
  catchAsync,
  isSuccess,
} from "../../helper/helper";
import { GET_MEDICINE_STOCK_URL } from "../../env";
import { CustomOption } from "../../helper/helperJsx";
import Input from "./Input";

const MedicationStocksDetail = ({
  onSelect,
  errorClass,
  customError,
  errorMessage,
  label,
  filterBy,
  defaultSelectedValue,
  isDisabled,
  isClearable,
  requiredStar,
  userId,
  onMedicationDataChange,
  onChange,
  onBlur,
  qtyName,
  qtyValue,
  touched,
  errors,
}) => {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValue, setDefaultValue] = useState(null);
  const [isNewMedication, setIsNewMedication] = useState(false); // To track new medication

  const apiQuery = {
    userId: userId,
    skip: 0,
    limit: 10000,
  };

  const processFetchRecords = (data) => {
    if (data?.status !== isSuccess) {
      // Handle error here
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    try {
      const response = await getRecords(GET_MEDICINE_STOCK_URL, apiQuery);
      if (response?.status === isSuccess) {
        const filteredMedicationData = response?.data?.result;
        const validMedicationData = filteredMedicationData?.filter(
          (item) => item?._id !== ""
        );
        const getMedicationData = validMedicationData?.map((item) => ({
          value: item?.medicationName,
          label: item?.medicationName,
        }));
        setUserData(getMedicationData);
        setIsLoading(false);
        if (!defaultValue && getMedicationData?.length > 0) {
          setDefaultValue(getMedicationData[0]);
          onSelect(getMedicationData[0]?.value);
        }
        if (onMedicationDataChange) {
          onMedicationDataChange(validMedicationData); // Pass validMedicationData to the parent component
        }
      } else {
        processFetchRecords(response);
      }
    } catch (error) {
      console.error("Error fetching medication records:", error);
      // Handle error here
    }
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    fetchAllRecords();
  }, [defaultSelectedValue]);

  const handleInputChange = (e) => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (defaultSelectedValue) {
      const defaultOption = userData.find(
        (option) => option.value === defaultSelectedValue
      );
      if (defaultOption) {
        setDefaultValue(defaultOption);
      }
    }
  }, [defaultSelectedValue, userData]);

  const handleChange = (selectedOption) => {
    setDefaultValue(selectedOption);
    onSelect(selectedOption?.value);

    // Check if the selected option is a new medication
    const isNew = !userData.some(
      (option) => option.value === selectedOption?.value
    );
    setIsNewMedication(isNew); // Update state based on whether it's new or not
  };

  return (
    <div
      className={`customSelectBox ${isDisabled ? "disabled" : ""} ${errorClass}`}
    >
      {isLoading ? (
        <p className="loadingText">Loading...</p>
      ) : (
        <>
          <CreatableSelect
            className="basic-single cursor-pointer"
            classNamePrefix="select"
            value={defaultValue}
            onInputChange={handleInputChange}
            options={userData}
            isDisabled={isDisabled}
            isSearchable
            isClearable={isClearable}
            onChange={handleChange}
            getOptionLabel={(selectedOption) => selectedOption?.label}
            getOptionValue={(selectedOption) => selectedOption?.value}
            components={{ Option: CustomOption }}
            formatCreateLabel={(inputValue) => `Create new: "${inputValue}"`}
          />
          {label && <label className="floatingLabel">{label}</label>}
          {customError && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
      {/* You can now use isNewMedication variable elsewhere in your component */}
      {isNewMedication && (
        <>
          <br />
          <Input
            type="number"
            id={qtyName}
            name={qtyName}
            value={qtyValue}
            onChange={onChange}
            onBlur={onBlur}
            labelText="Quantity"
            marginBottom={true}
            customError={touched?.qtyName && errors?.qtyName}
            errorMessage={errors?.qtyName}
            erroClass={
              touched?.qtyName && errors?.qtyName
                ? "validationError"
                : ""
            }
          />
        </>

      )}
    </div>
  );
};

export default MedicationStocksDetail;
