
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import Layout from "../../../components/layouts/Layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import useColumnsWithPermission from "../../../permissions/useColumnsWithPermission";
import {
    BASE_URL,
    GET_CLAIMED_LIVE_ROSTER_SHIFTS_URL,
    UPDATE_CLAIMED_LIVE_ROSTER_SHIFTS_URL,
} from "../../../env";
import {
    updateRecords,
    getRecords,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
    catchAsync,
} from "../../../helper/helper";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import UpdateClaimedShift from "./UpdateClaimedShift";
import StatusUpdated from "../../../components/general/StatusUpdated";
import ClaimedShiftStatusUpdated from "./ClaimedShiftStatusUpdated";
function ClaimedShifts() {
    const [userData, setUserData] = useState([]);
    const [search, setSearch] = useState("");
    const [filterSearch, setFilterSearch] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [shiftData, setShiftData] = useState({});
    // console.log("🚀 ~ ClaimedShifts ~ shiftData:", shiftData)
    const [isToastEditRosterShift, setIsToastEditRosterShift] = useState(false);

    const apiQuery = {
        sortproperty: "createdAt",
        sortorder: 0,
        offset: 0,
        limit: 10,
        query: {
            critarion: {}
        }
    };

    // Getting records from DB
    const processFetchRecords = (method, data) => {
        if (method === "get" && data?.status === isSuccess) {
            const actionRecentIncidentsData = data?.data?.claimedShifts;
            setUserData(actionRecentIncidentsData);
            setFilterSearch(actionRecentIncidentsData);
            setIsLoading(false);
        } else if (method === "delete" && data?.status === isSuccess) {
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
        }
    };

    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(GET_CLAIMED_LIVE_ROSTER_SHIFTS_URL, apiQuery);
        console.log("🚀 ~ fetchAllRecords ~ response:", response)
        processFetchRecords("get", response);
    });

    useEffect(() => {
        fetchAllRecords();
    }, []);

    // Grouping and sorting staff by createdAt (oldest first)
    const groupedData = {};
    userData.forEach((item) => {
        if (!groupedData[item.dayId]) {
            groupedData[item.dayId] = {
                dayName: item.dayName,
                shiftDate: item.shiftDate,
                staffInfo: [],
                serviceUsers: new Map(),
                requestType: item.requestType || "Claimed",
                isApproved: item.status === "APPROVED", // Track if the status is approved
                isRejected: item.status === "REJECTED", // Track if the status is approved
            };
        }

        groupedData[item.dayId].staffInfo.push({

            staffUserId: item.staffUserId,
            createdAt: item.createdAt,
            status: item.status,
            shiftId: item._id,
        });

        if (!groupedData[item.dayId].serviceUsers.has(item.serviceUserId?._id)) {
            groupedData[item.dayId].serviceUsers.set(item.serviceUserId?._id, {
                serviceUserId: item.serviceUserId,
                shiftDate: item.staffUserId?.shiftDate,
                shiftStartTime: item.shiftStartTime,
                shiftEndTime: item.shiftEndTime,
                workHrs: item.workHrs,
            });
        }
    });

    // Sort staff by createdAt (oldest first) in each grouped dayId
    Object.keys(groupedData).forEach((dayId) => {
        groupedData[dayId].staffInfo.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    });

    // Convert groupedData to an array of unique dayId entries
    const uniqueRows = Object.keys(groupedData).map((dayId) => ({
        dayId,
        dayName: groupedData[dayId].dayName,
        status: groupedData[dayId].status,
        shiftDate: groupedData[dayId].shiftDate,
        staffInfo: groupedData[dayId].staffInfo,
        serviceUsers: Array.from(groupedData[dayId].serviceUsers.values()),
        requestType: groupedData[dayId].requestType,
        isApproved: groupedData[dayId].isApproved, // Carry over isApproved flag
        isRejected: groupedData[dayId].isRejected, // Carry over isApproved flag
    }));

    const baseColumns = [
        {
            name: "Service user Detail",
            selector: (row) => {
                return row.serviceUsers.map((serviceUser, index) => (
                    <div key={index} className="serviceUserDetail">
                        <span className="usersNameInfo mt-3 mb-3">
                            <span className="userImage">
                                <img
                                    src={`${BASE_URL}${serviceUser?.serviceUserId?.profile_picture_url}`}
                                    alt={serviceUser?.serviceUserId?.name}
                                />
                            </span>
                            <span className="userInfo">
                                <span className="">
                                    {serviceUser?.serviceUserId?.modelId?.suFirstMiddleName}
                                    &nbsp;
                                    {serviceUser?.serviceUserId?.modelId?.suLastName}
                                </span>
                                <span className="nameBlock">
                                    <small>{moment(row?.shiftDate).format("DD/MM/YYYY")}</small>
                                </span>
                                <span className="nameBlock">
                                    <small>
                                        {serviceUser?.shiftStartTime} - {serviceUser?.shiftEndTime}
                                        &nbsp;({`${serviceUser?.workHrs} hours`})
                                    </small>
                                </span>
                            </span>
                        </span>
                    </div>
                ));
            },
            width: "20%"
        },
        {
            name: "Staff Members",
            cell: (row) => (
                <div className="staffMemberWrapper">
                    {row.staffInfo.map((staff, index) => (
                        <span className="usersNameInfo" key={index}>
                            <span className="userImage">
                                <img
                                    src={`${BASE_URL}${staff?.staffUserId?.profile_picture_url}`}
                                    alt={staff?.staffUserId?.name}
                                />
                            </span>
                            <span className="userInfo">
                                <span>{staff?.staffUserId?.name}</span>
                                {staff?.staffUserId?.modelId?.driverFields?.isDriver ? (
                                    <span className="nameBlock">
                                        <small>Driving: Yes</small>
                                    </span>
                                ) : (
                                    <span className="nameBlock">
                                        <small>Driving: No</small>
                                    </span>
                                )}
                                <span className="nameBlock">
                                    <small>
                                        {moment(staff.createdAt).format("DD/MM/YYYY / hh:mm A")}
                                    </small>
                                </span>
                                <ClaimedShiftStatusUpdated
                                    initialStatus={staff?.status}
                                    isSuccessReturn={""}
                                    onChange={(e) => subModalUpdateRosterUserShift(e.target.value, staff?.shiftId)}
                                    // isDisabled={staff?.status !== "PENDING" ? true : false}
                                />
                            </span>
                        </span>
                    ))}
                </div>
            ),
            width: "60%",
        },
        {
            name: "Request Type",
            selector: (row) => <span className="requestType">{row?.requestType === "CLAIMED" ? "Claimed" : "Cancelled"}</span>,
            center: true,
            width: "20%"
        },
    ];

    const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT, permissions.DELETE]);

    // Search Filter
    useEffect(() => {
        const result = uniqueRows.filter((row) => {
            // Check if any service user matches the search term
            return row.serviceUsers.some(serviceUser => {
                // Combine first and last name for the service user
                const fullName = `${serviceUser?.serviceUserId?.modelId?.suFirstMiddleName || ""} ${serviceUser?.serviceUserId?.modelId?.suLastName || ""}`.toLowerCase();

                // Check if the full name matches the search term
                return fullName.includes(search.toLowerCase());
            });
        });
        setFilterSearch(result);
    }, [search, userData]);

    // Update Status of the Shifts
    const subModalUpdateRosterUserShift = (onChanageReject, shiftId) => {
        setShiftData({
            claimShiftId: shiftId,
            status: onChanageReject === "rejected" ? "REJECTED" : onChanageReject === "approved" ? "APPROVED" : "PENDING"
        });
    };

    useEffect(() => {
        if (shiftData.claimShiftId) { // Check if shiftData has been updated
            postEditRosterShiftFormData();
            fetchAllRecords();
        }
    }, [shiftData]);

    const processPostRecords = (method, data) => {
        if (data?.status !== isSuccess) {
            setIsToastEditRosterShift(true);
            toastErrorMessages(toast.error, data?.message, "", 500, 500);
        } else if (
            method === "postEditRosterShiftFormData" &&
            data?.status === isSuccess
        ) {
            setIsToastEditRosterShift(true);
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
        }
    };

    const postEditRosterShiftFormData = catchAsync(async (req, res) => {
        const response = await updateRecords(UPDATE_CLAIMED_LIVE_ROSTER_SHIFTS_URL, shiftData);
        processPostRecords("postEditRosterShiftFormData", response);

        // console.log("🚀 ~ postEditRosterShiftFormData ~ response:", response)
    });


    const [showAddMedication, setShowAddMedication] = useState(false);
    const [claimedShiftData, setClaimedShiftData] = useState();
    // console.log("🚀 ~ ClaimedShifts ~ claimedShiftData:", claimedShiftData)
    const subModalAddMedicine = (row) => {
        setShowAddMedication(!showAddMedication);
        setClaimedShiftData(row)
        if (!showAddMedication) {
            fetchAllRecords();
        }

    };

    return (
        <>
            <Layout pageTitleText="Shifts Requests">
                <div className="rowClickUnset pbsPlanUpdated">
                    <div className="mainContent">
                        <div className="dataTableWrapper">
                            <DataTable
                                className="maxWidthTable minWidthTable"
                                columns={columns}
                                data={filterSearch}
                                pagination
                                paginationRowsPerPageOptions={[1, 25, 50, 100]}
                                paginationPerPage={10}
                                fixedHeader
                                fixedHeaderScrollHeight="520px"
                                // highlightOnHover
                                responsive={true}
                                subHeader={true}
                                subHeaderComponent={
                                    <div className="searchContainer">
                                        <div className="searchTextBox">
                                            <input
                                                type="text"
                                                placeholder="Search by Staff Member"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                }
                                subHeaderAlign="left"
                                progressPending={isLoading}
                                progressComponent={<ProgressComponent />}
                            />
                        </div>
                    </div>
                </div>
            </Layout>

            {/* Add Medication Stock */}
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                <Modal
                    show={showAddMedication}
                    onHide={subModalAddMedicine}
                    className="ftcModalPopup medicineInventoryStock"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Medicine</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="rosterPopup">
                        <UpdateClaimedShift
                            claimedShiftData={claimedShiftData}
                        />
                    </Modal.Body>
                </Modal>
            </ProtectedComponent>

            {isToastEditRosterShift && <ToastContainer />}
        </>
    );
}

export default ClaimedShifts;
