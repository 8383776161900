import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "../auth/PrivateRoute";
import ProtectedRoute from "../permissions/ProtectedRoute";
import { permissions } from "../permissions/roles";

// Pages
// import Dashboard from "../pages/dashboard/Dashboard";
import Advanced from "../pages/advanced/Advanced";
import BradfordScore from "../pages/bradford_score/BradfordScore";
import Holiday from "../pages/holiday/Holiday";
import MessagingSystem from "../pages/messaging_system/MessagingSystem";
import MessagingSystemAdvanced from "../pages/messaging_system_advanced/MessagingSystemAdvanced";
import Payroll from "../pages/payroll/Payroll";
import PayrollIndividual from "../pages/payroll/PayrollIndividual";
import Training from "../pages/training/Training";
import Supervisions from "../pages/supervisions/Supervisions";
import StaffRecords from "../pages/staff_records/StaffRecords";
import AddNewStaff from "../pages/staff_records/AddNewStaff";
import LiveRosterGroups from "../pages/roster/LiveRosterGroups";
// import PublishRosterGroups from "../pages/roster/PublishRosterGroups";
import StaffOverview from "../pages/staff_overview/StaffOverview";
import StaffMemberProfile from "../pages/staff_records/StaffMemberProfile";
import ServiceUsers from "../pages/service_users/profile/ServiceUsers";
import ServiceProfile from "../pages/service_users/profile/ServiceProfile";
import MedicineStock from "../pages/medicine_stock/MedicineStock";
import Documents from "../pages/documents/Documents";
import RecentIncidents from "../pages/incidents/RecentIncidents";
import PbsPlans from "../pages/pbs/PbsPlans";
import RiskAssessments from "../pages/risk_assessments/RiskAssessments";
import AddTraining from "../pages/training/AddTraining";
import Recruitment from "../pages/recruitment/Recruitment";
import AddCandidates from "../pages/recruitment/AddNewCandidates";
import RecruitmentApplicationProcess from "../pages/recruitment/RecruitmentApplicationProcess";
import ConsentCapacity from "../pages/consent_capacity/ConsentCapacity";
import CCAddNewForm from "../pages/consent_capacity/CCAddNewForm";
import CurrentHealthIssues from "../pages/health-issue/CurrentHealthIssues";
import AddHealthIssues from "../pages/health-issue/AddHealthIssues";
import Appointments from "../pages/appointments/Appointments";
// import LifeHistoryGoals from "../pages/life_history_goals/LifeHistoryGoals";
import Emar from "../pages/emar/Emar";
// import AddNewShift from "../pages/roster/AddNewShift";
import CarePlans from "../pages/care-plans/care-plan-docs/CarePlans";
import CommonOptions from "../pages/care-plans/common-options/CommonOptions";
import YoungInformation from "../pages/young-information/YoungInformation";
import OutStadingRequest from "../pages/service_users/enquiry/OutStadingRequest";
import InternalReviewsList from "../pages/service_users/reviews/InternalReviewsList";
import IndividualReviews from "../pages/service_users/reviews/IndividualReviews";
import ServiceUserEnquiries from "../pages/service_users/enquiry/ServiceUserEnquiries";
import Rota from "../pages/rota/RotaSchedule";
import RotaTemplateGroups from "../pages/rota/RotaTemplateGroups";
import RotaSchedule from "../pages/rota/RotaSchedule";
import AddNewServiceUser from "../pages/service_users/AddNewServiceUser";
import AddReview from "../pages/service_users/reviews/AddReview";
import AddMedicineStock from "../pages/medicine_stock/AddMedicineStock";
import AppointmentDummy from "../pages/appointments/AppointmentDummy";
import AddRiskAssessments from "../pages/risk_assessments/AddRiskAssessments";
import EditAssessments from "../pages/risk_assessments/EditAssessments";
import AddMedicationSchedule from "../pages/emar/AddMedicationSchedule";
import EditMedicationSchedule from "../pages/emar/EditMedicationSchedule";
import ViewIndividualReviews from "../pages/service_users/reviews/ViewIndividualReviews";
import EditReview from "../pages/service_users/reviews/EditReview";
import MemoryBox from "../pages/care-plans/memory_box/MemoryBox";
import AddPbsPlans from "../pages/pbs/AddPbsPlans";
import EditPbsPlans from "../pages/pbs/EditPbsPlans";
import ViewPbsPlan from "../pages/pbs/ViewPbsPlan";
import AddRecentIncidents from "../pages/incidents/AddRecentIncidents";
import ViewRecentIncident from "../pages/incidents/ViewRecentIncident";
import EditRecentIncidents from "../pages/incidents/EditRecentIncidents";
import CareNotes from "../pages/care-plans/care-notes/CareNotes";
import AddCarePlan from "../pages/care-plans/care-plan-docs/AddCarePlan";
import ViewCarePlan from "../pages/care-plans/care-plan-docs/ViewCarePlan";
import ViewQualitySurvey from "../pages/care-plans/quality-survey/ViewQualitySurvey";
import AddQualitySurvey from "../pages/care-plans/quality-survey/AddQualitySurvey";
import AllGeneralCareNotes from "../pages/care-plans/common-options/general-note/AllGeneralCareNotes";
// import AddGeneralCareNotes from "../pages/care-plans/common-options/general-note/AddGeneralCareNotes";
// import EditGeneralCareNotes from "../pages/care-plans/common-options/general-note/EditGeneralCareNotes";
import AssignUser from "../pages/service_users/assign-user/AssignUser";
import HealthAppointments from "../pages/care-plans/common-options/category/health/health-appointments/HealthAppointments";
import AddHealthAppointments from "../pages/care-plans/common-options/category/health/health-appointments/AddHealthAppointments";
import EditHealthAppointments from "../pages/care-plans/common-options/category/health/health-appointments/EditHealthAppointments";
import AddInjuryHealthIssue from "../pages/care-plans/common-options/category/health/injury-health-issue/AddInjuryHealthIssue";
import EditInjuryHealthIssue from "../pages/care-plans/common-options/category/health/injury-health-issue/EditInjuryHealthIssue";
import AddObservations from "../pages/care-plans/common-options/category/health/observations/AddObservations";
import EditObservations from "../pages/care-plans/common-options/category/health/observations/EditObservations";
import AddWeightHeight from "../pages/care-plans/common-options/category/health/weight-height/AddWeightHeight";
import EditWeightHeight from "../pages/care-plans/common-options/category/health/weight-height/EditWeightHeight";
import AddHealthOthers from "../pages/care-plans/common-options/category/health/others/AddHealthOthers";
import EditHealthOthers from "../pages/care-plans/common-options/category/health/others/EditHealthOthers";
import EditToileting from "../pages/care-plans/common-options/category/personal-care/toileting/EditToileting";
import AddToileting from "../pages/care-plans/common-options/category/personal-care/toileting/AddToileting";
import ShoweringBath from "../pages/care-plans/common-options/category/personal-care/showering-bath/ShoweringBath";
import EditShoweringBath from "../pages/care-plans/common-options/category/personal-care/showering-bath/EditShoweringBath";
import AddShoweringBath from "../pages/care-plans/common-options/category/personal-care/showering-bath/AddShoweringBath";
import EditPersonalCareOthers from "../pages/care-plans/common-options/category/personal-care/others/EditPersonalCareOthers";
import AddPersonalCareOthers from "../pages/care-plans/common-options/category/personal-care/others/AddPersonalCareOthers";
import EditMouthHygiene from "../pages/care-plans/common-options/category/personal-care/mouth-hygiene/EditMouthHygiene";
import AddMouthHygiene from "../pages/care-plans/common-options/category/personal-care/mouth-hygiene/AddMouthHygiene";
import AddActivities from "../pages/care-plans/common-options/category/general/activities/AddActivities";
import EditActivities from "../pages/care-plans/common-options/category/general/activities/EditActivities";
import AddGeneralNote from "../pages/care-plans/common-options/category/general/general-note/AddGeneralNote";
import EditGeneralNote from "../pages/care-plans/common-options/category/general/general-note/EditGeneralNote";
import AddGeneralOthers from "../pages/care-plans/common-options/category/general/others/AddGeneralOthers";
import EditGeneralOthers from "../pages/care-plans/common-options/category/general/others/EditGeneralOthers";
import Safeguarding from "../pages/care-plans/common-options/category/general/safeguarding/Safeguarding";
import AddSafeguarding from "../pages/care-plans/common-options/category/general/safeguarding/AddSafeguarding";
import EditSafeguarding from "../pages/care-plans/common-options/category/general/safeguarding/EditSafeguarding";
import AddSleepInformation from "../pages/care-plans/common-options/category/general/sleep-information/AddSleepInformation";
import EditSleepInformation from "../pages/care-plans/common-options/category/general/sleep-information/EditSleepInformation";
import AddABC from "../pages/care-plans/common-options/category/mental-wellbeing/abc/AddABC";
import EditABC from "../pages/care-plans/common-options/category/mental-wellbeing/abc/EditABC";
import AddConsentCapacityMCADOLS from "../pages/care-plans/common-options/category/mental-wellbeing/consent-capacity-mca-dols/AddConsentCapacityMCADOLS";
import EditConsentCapacityMCADOLS from "../pages/care-plans/common-options/category/mental-wellbeing/consent-capacity-mca-dols/EditConsentCapacityMCADOLS";
import AddMoodRating from "../pages/care-plans/common-options/category/mental-wellbeing/mood-rating/AddMoodRating";
import EditMoodRating from "../pages/care-plans/common-options/category/mental-wellbeing/mood-rating/EditMoodRating";
import AddMentalWellBeingOthers from "../pages/care-plans/common-options/category/mental-wellbeing/others/AddMentalWellBeingOthers";
import EditMentalWellBeingOthers from "../pages/care-plans/common-options/category/mental-wellbeing/others/EditMentalWellBeingOthers";
import AddPhysicalIntervention from "../pages/care-plans/common-options/category/mental-wellbeing/physical-intervention/AddPhysicalIntervention";
import EditPhysicalIntervention from "../pages/care-plans/common-options/category/mental-wellbeing/physical-intervention/EditPhysicalIntervention";
import AddAllOtherInteractions from "../pages/care-plans/common-options/category/professional-family-interactions/all-other-interactions/AddAllOtherInteractions";
import EditAllOtherInteractions from "../pages/care-plans/common-options/category/professional-family-interactions/all-other-interactions/EditAllOtherInteractions";
import AddEmails from "../pages/care-plans/common-options/category/professional-family-interactions/emails/AddEmails";
import EditEmails from "../pages/care-plans/common-options/category/professional-family-interactions/emails/EditEmails";
import AddMeetings from "../pages/care-plans/common-options/category/professional-family-interactions/meetings/AddMeetings";
import EditMeetings from "../pages/care-plans/common-options/category/professional-family-interactions/meetings/EditMeetings";
import AddProfessionalFamilyInteractionsOthers from "../pages/care-plans/common-options/category/professional-family-interactions/others/AddProfessionalFamilyInteractionsOthers";
import EditProfessionalFamilyInteractionsOthers from "../pages/care-plans/common-options/category/professional-family-interactions/others/EditProfessionalFamilyInteractionsOthers";
import AddReviews from "../pages/care-plans/common-options/category/professional-family-interactions/reviews/AddReviews";
import EditReviews from "../pages/care-plans/common-options/category/professional-family-interactions/reviews/EditReviews";
import AddTelephoneCalls from "../pages/care-plans/common-options/category/professional-family-interactions/telephone-calls/AddTelephoneCalls";
import EditTelephoneCalls from "../pages/care-plans/common-options/category/professional-family-interactions/telephone-calls/EditTelephoneCalls";
import AddCleaning from "../pages/care-plans/common-options/category/independent-living/cleaning/AddCleaning";
import EditCleaning from "../pages/care-plans/common-options/category/independent-living/cleaning/EditCleaning";
import AddCooking from "../pages/care-plans/common-options/category/independent-living/cooking/AddCooking";
import EditCooking from "../pages/care-plans/common-options/category/independent-living/cooking/EditCooking";
import AddFinance from "../pages/care-plans/common-options/category/independent-living/finance/AddFinance";
import EditFinance from "../pages/care-plans/common-options/category/independent-living/finance/EditFinance";
import AddEducation from "../pages/care-plans/common-options/category/independent-living/education/AddEducation";
import EditEducation from "../pages/care-plans/common-options/category/independent-living/education/EditEducation";
import AddLaundry from "../pages/care-plans/common-options/category/independent-living/laundry/AddLaundry";
import EditLaundry from "../pages/care-plans/common-options/category/independent-living/laundry/EditLaundry";
import AddNutritionHydration from "../pages/care-plans/common-options/category/independent-living/nutrition-hydration/AddNutritionHydration";
import EditNutritionHydration from "../pages/care-plans/common-options/category/independent-living/nutrition-hydration/EditNutritionHydration";
import AddIndependentLivingOthers from "../pages/care-plans/common-options/category/independent-living/others/AddIndependentLivingOthers";
import EditIndependentLivingOthers from "../pages/care-plans/common-options/category/independent-living/others/EditIndependentLivingOthers";
import AddPublicInteraction from "../pages/care-plans/common-options/category/independent-living/public-interaction/AddPublicInteraction";
import EditPublicInteraction from "../pages/care-plans/common-options/category/independent-living/public-interaction/EditPublicInteraction";
import CrisisManagement from "../pages/support-plan/crisis-management/CrisisManagement";
import ModifyCrisisManagement from "../pages/support-plan/crisis-management/ModifyCrisisManagement";
import AddCrisisManagement from "../pages/support-plan/crisis-management/AddCrisisManagement";
import InjuryHealthIssue from "../pages/care-plans/common-options/category/health/injury-health-issue/InjuryHealthIssue";
import WeightHeight from "../pages/care-plans/common-options/category/health/weight-height/WeightHeight";
import Toileting from "../pages/care-plans/common-options/category/personal-care/toileting/Toileting";
import MoodRating from "../pages/care-plans/common-options/category/mental-wellbeing/mood-rating/MoodRating";
import ABC from "../pages/care-plans/common-options/category/mental-wellbeing/abc/ABC";
import PhysicalIntervention from "../pages/care-plans/common-options/category/mental-wellbeing/physical-intervention/PhysicalIntervention";
import ConsentCapacityMCADOLS from "../pages/care-plans/common-options/category/mental-wellbeing/consent-capacity-mca-dols/ConsentCapacityMCADOLS";
import NutritionHydration from "../pages/care-plans/common-options/category/independent-living/nutrition-hydration/NutritionHydration";
import Observations from "../pages/care-plans/common-options/category/health/observations/Observations";
import DailySummary from "../pages/care-plans/common-options/daily-summary/DailySummary";
import MedicineList from "../pages/medicine_list/MedicineList";
import AddMedicineList from "../pages/medicine_list/AddMedicineList";
import EditMedicineList from "../pages/medicine_list/EditMedicineList";
import EditCandidates from "../pages/recruitment/EditCandidates";
import RotaTemplateList from "../pages/rota_advanced/RotaTemplateList";
import RotaTemplateSchedule from "../pages/rota_advanced/schedule/RotaTemplateSchedule";
import LiveRosterList from "../pages/roster_advanced/LiveRosterList";
import ShiftsActions from "../pages/roster_advanced/shifts_actions/ShiftsActions";
import ClaimedShifts from "../pages/roster_advanced/claimed_shifts/ClaimedShifts";
import GetFinalPayrollList from "../pages/payroll/finalization/GetFinalPayrollList";
import AddQuizzes from "../pages/training/quizzes/AddQuizzes";
import QuizzesList from "../pages/training/quizzes/QuizzesList";
import EditQuizzes from "../pages/training/quizzes/EditQuizzes";
import ArchieveStaffRecords from "../pages/staff_records/archieved_staff/ArchieveStaffRecords";

function BasicRoutes() {
  return (
    <>

      <Routes>
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route
          path="/advanced"
          element={<PrivateRoute element={<ProtectedRoute element={<Advanced />} requiredPermissions={[permissions.ADMIN]} />} />}
        />

        <Route
          path="/bradford-score"
          element={<PrivateRoute element={<ProtectedRoute element={<BradfordScore />} requiredPermissions={[permissions.HOLIDAYS]} />} />}
        />

        <Route path="/holiday" element={<PrivateRoute element={<Holiday />} />} />
        <Route path="/messaging-system" element={<PrivateRoute element={<MessagingSystemAdvanced />} />} />
        <Route path="/messaging-system-advanced" element={<PrivateRoute element={<MessagingSystem />} />} />



        <Route
          path="/payroll"
          element={<PrivateRoute element={<ProtectedRoute element={<Payroll />} requiredPermissions={[permissions.PAYROLL]} />} />}
        />

        <Route
          path="/final-payroll"
          element={<PrivateRoute element={<ProtectedRoute element={<GetFinalPayrollList />} requiredPermissions={[permissions.PAYROLL]} />} />}
        />

        <Route
          path="/payroll/:id"
          element={<PrivateRoute element={<ProtectedRoute element={<PayrollIndividual />} requiredPermissions={[permissions.PAYROLL]} />} />}
        />

        <Route
          path="/live-roster"
          element={<PrivateRoute element={<ProtectedRoute element={<LiveRosterList />} requiredPermissions={[permissions.ROSTER]} />} />}
        />

        <Route
          path="/shifts-actions"
          element={<PrivateRoute element={<ProtectedRoute element={<ShiftsActions />} requiredPermissions={[permissions.ROSTER]} />} />}
        />

        <Route
          path="/shifts-requests"
          element={<PrivateRoute element={<ProtectedRoute element={<ClaimedShifts />} requiredPermissions={[permissions.ROSTER]} />} />}
        />

        <Route
          path="/rota"
          element={<PrivateRoute element={<ProtectedRoute element={<Rota />} requiredPermissions={[permissions.ROSTER]} />} />}
        />
        <Route
          path="/rota-templates"
          element={<PrivateRoute element={<ProtectedRoute element={<RotaTemplateGroups />} requiredPermissions={[permissions.ROSTER]} />} />}
        />
        <Route
          path="/rota-schedule"
          element={<PrivateRoute element={<ProtectedRoute element={<RotaSchedule />} requiredPermissions={[permissions.ROSTER]} />} />}
        />
        {/* <Route path="/publish-roster" element={<PrivateRoute element={<PublishRosterGroups />} />} /> */}


        {/* Advance Live Roster */}
        {/* <Route path="/live-roster-advanced" element={<PrivateRoute element={<LiveRosterList />} />} /> */}

        {/* Advance Rota Template */}
        <Route
          path="/rota-advanced"
          element={<PrivateRoute element={<ProtectedRoute element={<RotaTemplateList />} requiredPermissions={[permissions.ROSTER]} />} />}
        />
        <Route
          path="/rota-users-schedule"
          element={<PrivateRoute element={<ProtectedRoute element={<RotaTemplateSchedule />} requiredPermissions={[permissions.ROSTER]} />} />}
        />

        {/* <Route path="/add-new-service" element={<PrivateRoute element={<AddNewShift />} />} /> */}
        <Route path="/training" element={<PrivateRoute element={<Training />} />} />

        <Route path="/training-quizzes" element={<PrivateRoute element={<QuizzesList />} />} />


        <Route
          path="/supervisions"
          element={<PrivateRoute element={<ProtectedRoute element={<Supervisions />} requiredPermissions={[permissions.SUPERVISION]} />} />}
        />

        <Route
          path="/recruitment"
          element={<PrivateRoute element={<ProtectedRoute element={<Recruitment />} requiredPermissions={[permissions.RECRUITMENT]} />} />}
        />

        <Route
          path="/recruitment-application-process"
          element={<PrivateRoute element={<ProtectedRoute element={<RecruitmentApplicationProcess />} requiredPermissions={[permissions.RECRUITMENT]} />} />}
        />


        <Route path="/staff-records" element={<PrivateRoute element={<StaffRecords />} />} />
        <Route
          path="/archieved-staff-records"
          element={<PrivateRoute element={<ProtectedRoute element={<ArchieveStaffRecords />} requiredPermissions={[permissions.ADMIN]} />} />}
        />

        <Route path="/staff-overview" element={<PrivateRoute element={<StaffOverview />} />} />
        {/* <Route path="/staff-overview" element={<StaffOverview />} />} /> */}
        <Route path="/staff-profile" element={<PrivateRoute element={<StaffMemberProfile />} />} />
        <Route path="/service-users" element={<PrivateRoute element={<ServiceUsers />} />} />
        <Route path="/service-user-profile" element={<PrivateRoute element={<ServiceProfile />} />} />

        <Route path="/outstanding-request" element={<PrivateRoute element={<OutStadingRequest />} />} />
        <Route path="/internal-reviews" element={<PrivateRoute element={<InternalReviewsList />} />} />
        <Route path="/individual-reviews" element={<PrivateRoute element={<IndividualReviews />} />} />
        <Route
          path="/view-individual-reviews"
          element={<PrivateRoute element={<ViewIndividualReviews />} />} />

        <Route path="/care-plans" element={<PrivateRoute element={<CarePlans />} />} />
        <Route path="/view-care-plan" element={<PrivateRoute element={<ViewCarePlan />} />} />
        <Route path="/care-notes" element={<PrivateRoute element={<CareNotes />} />} />
        <Route path="/memory-box" element={<PrivateRoute element={<MemoryBox />} />} />
        <Route path="/young-information" element={<PrivateRoute element={<YoungInformation />} />} />
        <Route path="/quality-survey" element={<PrivateRoute element={<ViewQualitySurvey />} />} />

        {/* Care Plan Common Options */}

        <Route path="/common-options" element={<PrivateRoute element={<CommonOptions />} />} />
        <Route path="/care-plan/general-notes" element={<PrivateRoute element={<AllGeneralCareNotes />} />} />
        <Route path="/care-plan/daily-summary" element={<PrivateRoute element={<DailySummary />} />} />
        {/* <Route path="/add-general-notes" element={<PrivateRoute element={<AddGeneralCareNotes />} />} />
        <Route path="/edit-general-notes" element={<PrivateRoute element={<EditGeneralCareNotes />} />} /> */}
        {/* Health */}
        <Route
          path="/care-plan/health-appointments"
          element={<PrivateRoute element={<HealthAppointments />} />} />
        {/* <Route
          path="/care-plan/health-appointments"
          element={<PrivateRoute element={<HealthAppointments />} />} />
          <Route
          path="/care-plan/add-health-appointments"
          element={<PrivateRoute element={<AddHealthAppointments />} />} />
          <Route
          path="/care-plan/edit-health-appointments-notes"
          element={<PrivateRoute element={<EditHealthAppointments />} />} /> 
           <Route
          path="/care-plan/injury-health-issue"
          element={<PrivateRoute element={<InjuryHealthIssue />} />} /> */}
        <Route path="/care-plan/weight-height" element={<PrivateRoute element={<WeightHeight />} />} />

        {/* General */}

        {/* <Route path="/care-plan/safeguarding" element={<PrivateRoute element={<Safeguarding />} />} /> */}
        {/* <Route path="/care-plan/showering-bath" element={<PrivateRoute element={<ShoweringBath />} />} /> */}
        {/* <Route path="/care-plan/toileting" element={<PrivateRoute element={<Toileting />} />} /> */}

        {/* MentalWellbeing */}

        <Route path="/care-plan/abc" element={<PrivateRoute element={<ABC />} />} />
        <Route
          path="/care-plan/consent-capacity-mca-dols"
          element={<PrivateRoute element={<ConsentCapacityMCADOLS />} />} />
        {/* <Route path="/care-plan/mood-rating" element={<PrivateRoute element={<MoodRating />} />} /> */}
        {/* <Route
          path="/care-plan/physical-intervention"
          element={<PrivateRoute element={<PhysicalIntervention />} />} /> */}
        {/* ProfessionalFamilyInteractions */}
        {/* IndependentLiving */}
        {/* <Route
          path="/care-plan/nutrition-hydration"
          element={<PrivateRoute element={<NutritionHydration />}
          /> */}
        <Route
          path="/support-plan/crisis-management"
          element={<PrivateRoute element={<CrisisManagement />} />} />
        <Route
          path="/support-plan/modify-crisis-management"
          element={<PrivateRoute element={<ModifyCrisisManagement />} />} />
        <Route path="/medicine-list" element={<PrivateRoute element={<MedicineList />} />} />

        {/* Routes not present in Figma */}

        <Route path="/medicine-stock" element={<PrivateRoute element={<MedicineStock />} />} />
        <Route path="/documents" element={<PrivateRoute element={<Documents />} />} />
        <Route path="/recent-incidents" element={<PrivateRoute element={<RecentIncidents />} />} />
        <Route path="/view-incidents" element={<PrivateRoute element={<ViewRecentIncident />} />} />
        <Route path="/pbs-plans" element={<PrivateRoute element={<PbsPlans />} />} />
        <Route path="/view-plans" element={<PrivateRoute element={<ViewPbsPlan />} />} />
        <Route path="/risk-assessments" element={<PrivateRoute element={<RiskAssessments />} />} />
        <Route path="/consent-capacity" element={<PrivateRoute element={<ConsentCapacity />} />} />
        <Route
          path="/current-health-issues"
          element={<PrivateRoute element={<CurrentHealthIssues />} />} />
        <Route path="/appointments" element={<PrivateRoute element={<Appointments />} />} />
        <Route path="/appointments-dummy" element={<PrivateRoute element={<AppointmentDummy />} />} />
        {/* <Route path="/life-history-goals" element={<PrivateRoute element={<LifeHistoryGoals />} />} /> */}
        <Route path="/emar" element={<PrivateRoute element={<Emar />} />} />

        {/* Roll Based Access Control for ADDING Data Routes */}

        <Route
          path="/add-service-user"
          element={<PrivateRoute element={<ProtectedRoute element={<AddNewServiceUser />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-reviews"
          element={<PrivateRoute element={<ProtectedRoute element={<AddReview />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/service-user-enquiries"
          element={<PrivateRoute element={<ProtectedRoute element={<ServiceUserEnquiries />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-care-plans"
          element={<PrivateRoute element={<ProtectedRoute element={<AddCarePlan />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-quality-survey"
          element={<PrivateRoute element={<ProtectedRoute element={<AddQualitySurvey />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-health-appointments"
          element={<PrivateRoute element={<ProtectedRoute element={<AddHealthAppointments />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-injury-health-issue"
          element={<PrivateRoute element={<ProtectedRoute element={<AddInjuryHealthIssue />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-observations"
          element={<PrivateRoute element={<ProtectedRoute element={<AddObservations />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-health-other"
          element={<PrivateRoute element={<ProtectedRoute element={<AddHealthOthers />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-weight-height"
          element={<PrivateRoute element={<ProtectedRoute element={<AddWeightHeight />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-activities-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<AddActivities />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-general-note"
          element={<PrivateRoute element={<ProtectedRoute element={<AddGeneralNote />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-general-other"
          element={<PrivateRoute element={<ProtectedRoute element={<AddGeneralOthers />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-safeguarding"
          element={<PrivateRoute element={<ProtectedRoute element={<AddSafeguarding />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-sleep-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<AddSleepInformation />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-mouth-hygiene"
          element={<PrivateRoute element={<ProtectedRoute element={<AddMouthHygiene />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-personal-care-other"
          element={<PrivateRoute element={<ProtectedRoute element={<AddPersonalCareOthers />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-showering-bath"
          element={<PrivateRoute element={<ProtectedRoute element={<AddShoweringBath />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-toileting"
          element={<PrivateRoute element={<ProtectedRoute element={<AddToileting />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-abc"
          element={<PrivateRoute element={<ProtectedRoute element={<AddABC />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-consent-capacity-mca-dols"
          element={<PrivateRoute element={<ProtectedRoute element={<AddConsentCapacityMCADOLS />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-mood-rating"
          element={<PrivateRoute element={<ProtectedRoute element={<AddMoodRating />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-mental-wellbeing-other"
          element={<PrivateRoute element={<ProtectedRoute element={<AddMentalWellBeingOthers />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-physical-intervention"
          element={<PrivateRoute element={<ProtectedRoute element={<AddPhysicalIntervention />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-all-other-interactions"
          element={<PrivateRoute element={<ProtectedRoute element={<AddAllOtherInteractions />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-emails"
          element={<PrivateRoute element={<ProtectedRoute element={<AddEmails />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-meetings"
          element={<PrivateRoute element={<ProtectedRoute element={<AddMeetings />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-professional-family-interactions-other"
          element={<PrivateRoute element={<ProtectedRoute element={<AddProfessionalFamilyInteractionsOthers />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-reviews"
          element={<PrivateRoute element={<ProtectedRoute element={<AddReviews />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-telephone-calls"
          element={<PrivateRoute element={<ProtectedRoute element={<AddTelephoneCalls />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-cleaning"
          element={<PrivateRoute element={<ProtectedRoute element={<AddCleaning />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-cooking"
          element={<PrivateRoute element={<ProtectedRoute element={<AddCooking />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-finance"
          element={<PrivateRoute element={<ProtectedRoute element={<AddFinance />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-education"
          element={<PrivateRoute element={<ProtectedRoute element={<AddEducation />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-laundry"
          element={<PrivateRoute element={<ProtectedRoute element={<AddLaundry />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-nutrition-hydration"
          element={<PrivateRoute element={<ProtectedRoute element={<AddNutritionHydration />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-independent-living-other"
          element={<PrivateRoute element={<ProtectedRoute element={<AddIndependentLivingOthers />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/care-plan/add-public-interaction"
          element={<PrivateRoute element={<ProtectedRoute element={<AddPublicInteraction />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/support-plan/add-crisis-management"
          element={<PrivateRoute element={<ProtectedRoute element={<AddCrisisManagement />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-medicine-list"
          element={<PrivateRoute element={<ProtectedRoute element={<AddMedicineList />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-medicine-stock"
          element={<PrivateRoute element={<ProtectedRoute element={<AddMedicineStock />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-incidents"
          element={<PrivateRoute element={<ProtectedRoute element={<AddRecentIncidents />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-plans"
          element={<PrivateRoute element={<ProtectedRoute element={<AddPbsPlans />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-risk-assessments"
          element={<PrivateRoute element={<ProtectedRoute element={<AddRiskAssessments />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-consent-capacity"
          element={<PrivateRoute element={<ProtectedRoute element={<CCAddNewForm />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-new-staff"
          element={<PrivateRoute element={<ProtectedRoute element={<AddNewStaff />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-training"
          element={<PrivateRoute element={<ProtectedRoute element={<AddTraining />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-training-quizzes"
          element={<PrivateRoute element={<ProtectedRoute element={<AddQuizzes />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/edit-training-quizzes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditQuizzes />} requiredPermissions={[permissions.EDIT]} />} />}
        />

        <Route
          path="/add-new-candidate"
          element={<PrivateRoute element={<ProtectedRoute element={<AddCandidates />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-medication"
          element={<PrivateRoute element={<ProtectedRoute element={<AddMedicationSchedule />} requiredPermissions={[permissions.ADD]} />} />}
        />
        <Route
          path="/add-health-issues"
          element={<PrivateRoute element={<ProtectedRoute element={<AddHealthIssues />} requiredPermissions={[permissions.ADD]} />} />}
        />


        {/* Roll Based Access Control for EDITING Data Routes */}

        <Route
          path="/assign-staff"
          element={<PrivateRoute element={<ProtectedRoute element={<AssignUser />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/edit-candidate"
          element={<PrivateRoute element={<ProtectedRoute element={<EditCandidates />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/edit-reviews"
          element={<PrivateRoute element={<ProtectedRoute element={<EditReview />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-health-appointments-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditHealthAppointments />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-injury-health-issue-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditInjuryHealthIssue />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-observations-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditObservations />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-health-other-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditHealthOthers />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-weight-height-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditWeightHeight />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-activities-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditActivities />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-general-note-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditGeneralNote />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-general-other-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditGeneralOthers />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-safeguarding-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditSafeguarding />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-sleep-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditSleepInformation />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-mouth-hygiene-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditMouthHygiene />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-personal-care-other-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditPersonalCareOthers />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-showering-bath-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditShoweringBath />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-toileting-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditToileting />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-abc-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditABC />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-consent-capacity-mcadols-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditConsentCapacityMCADOLS />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-mood-rating-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditMoodRating />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-mental-wellbeing-other-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditMentalWellBeingOthers />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-physical-intervention-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditPhysicalIntervention />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-all-other-interactions-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditAllOtherInteractions />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-emails-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditEmails />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-meetings-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditMeetings />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-professional-family-interactions-other-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditProfessionalFamilyInteractionsOthers />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route path="/care-plan/edit-reviews-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditReviews />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-telephone-calls-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditTelephoneCalls />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-cleaning-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditCleaning />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-cooking-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditCooking />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-finance-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditFinance />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-education-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditEducation />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route path="/care-plan/edit-laundry-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditLaundry />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-nutrition-hydration-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditNutritionHydration />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-independent-living-other-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditIndependentLivingOthers />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/care-plan/edit-public-interaction-notes"
          element={<PrivateRoute element={<ProtectedRoute element={<EditPublicInteraction />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/edit-medicine-list"
          element={<PrivateRoute element={<ProtectedRoute element={<EditMedicineList />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/edit-incidents"
          element={<PrivateRoute element={<ProtectedRoute element={<EditRecentIncidents />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/edit-plans"
          element={<PrivateRoute element={<ProtectedRoute element={<EditPbsPlans />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/edit-risk-assessments"
          element={<PrivateRoute element={<ProtectedRoute element={<EditAssessments />} requiredPermissions={[permissions.EDIT]} />} />}
        />
        <Route
          path="/edit-medication"
          element={<PrivateRoute element={<ProtectedRoute element={<EditMedicationSchedule />} requiredPermissions={[permissions.EDIT]} />} />}
        />

      </Routes>
    </>
  );
}

export default BasicRoutes;
