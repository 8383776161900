import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Input from "../forms-fields/Input";
import actionButtonDel from "../../assets/images/icons/actionButtonDel.svg";
import camera from "../../assets/images/icons/camera.svg";
import loadingGif from "../../assets/images/icons/loadingGif.gif";
import closeImage from "../../assets/images/icons/closeImage.svg";
import TextArea from "../forms-fields/TextArea";
import moment from 'moment';
import {
  BASE_URL,
  UPDATE_SERVICE_USER_BY_ID_URL,
  GET_SERVICE_USER_BY_ID_URL,
  GET_ALL_SERVICE_USERS_URL,
  ADMIN_AUTH_TOKEN,
  UPLOAD_MEMORY_BOX_FILE,
  PUT_USERS_PROFILE_PICTURE_URL,
  PUT_USERS_PROFILE_VIDEO_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  formateDate,
  updateRecords,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  apiQuery,
} from "../../helper/helper";
import {
  uploadFile,
} from "../../helper/helperJsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectBox from "../forms-fields/SelectBox";
import { addSUSignUp } from "../../schemas";
const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

const currentStatus = [
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

function ServiceUserProfileUpdate({ showModal, closeModal, profileDataId }) {
  const [updateUserDetails, setUpdateUserDetails] = useState({});
  console.log("🚀 ~ updateUserDetails:", updateUserDetails)
  const [isLoadingIcon, setIsLoadingIcon] = useState(false);
  // const [uploadedFilePath, setUploadedFilePath] = useState("");
  // console.log("🚀 ~ uploadedFilePath:", uploadedFilePath)
  const [isFileUploadedToast, setIsFileUploadedToast] = useState(false);
  const [isToastUpdateUserRecord, setIsToastUpdateUserRecord] = useState(false);
  // console.log("🚀 ~ updateUserDetails:", updateUserDetails)
  const [profileVideoUrl, setProfileVideoUrl] = useState(null);
  const [videoSizeError, setVideoSizeError] = useState(false)
  // console.log("🚀 ~ profileVideoUrl:", profileVideoUrl)
  const [profilePicture, setProfilePicture] = useState();
  useEffect(() => {
    setProfilePicture(BASE_URL + updateUserDetails?.profile_picture_url)
  })
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [firstName, setFirstName] = useState()
  useEffect(() => {
    setFirstName(updateUserDetails?.modelId?.suFirstMiddleName ? (
      updateUserDetails?.modelId?.suFirstMiddleName
    ) : (
      updateUserDetails?.name
    ))
  }, [updateUserDetails])
  //Get Single SU
  const fetchUserData = async () => {
    try {
      const getEnquiry = await getRecords(
        GET_ALL_SERVICE_USERS_URL,
        profileDataId
      );
      if (getEnquiry?.status === isSuccess) {
        setUpdateUserDetails(getEnquiry?.data?.users[0]);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };


  const generateRandomRefID = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const digits = '0123456789';

    const getRandom = (pool, length) =>
      Array.from({ length }, () => pool[Math.floor(Math.random() * pool.length)]).join('');

    const randomLetters = getRandom(letters, 3);
    const randomDigits = getRandom(digits, 6);

    return `${randomLetters}-${randomDigits}`;
  };
  const [refID, setRefID] = useState(generateRandomRefID());

  const generateNewRefID = () => {
    setRefID(generateRandomRefID());
  };

  useEffect(() => {
    fetchUserData();
  }, []);
  // const initialValues = {
  //   name: "",
  //   suLastName: "",
  //   suPreferredName: "",
  //   suSsn: "",
  //   suDOB: "",
  //   suAge: "",
  //   suReferredBY: "",
  //   suFamilyHead: "",
  //   suAddress1: "",
  //   suAddress2: "",
  //   suCity: "",
  //   suState: "",
  //   suFirstVisitDate: "",
  //   suLastVisitDate: "",
  //   providerName: "",
  //   suHomePhone: "",
  //   suWorkPhone: "",
  //   suMobileHomeNo: "",
  //   suMobileWorkNo: "",
  //   suEmailHome: "",
  //   suEmailWork: "",
  //   suPrefHomeNo: "",
  //   suPrefWorkNo: "",
  //   suEmergencyContact: "",
  //   seMedicalAlert: "",
  //   suNote: "",
  // };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: updateUserDetails,
    validationSchema: addSUSignUp,
    onSubmit: async (values) => {
      setIsSubmitButtonClicked(true);
      try {
        // console.log("My Values: ", values);
        setIsSubmitButtonClicked(true);
        await updateFormDataNewServiceUsers(values);
        fetchUserData();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const updateEnquiryRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToast(true);
      toastErrorMessages(toast.error, data?.message, "", 1000, 1000);
      setIsSubmitButtonClicked(false);
    }
    if (method === "updateFormDataNewServiceUsers" && data?.status === isSuccess) {
      setIsToast(true);
      setIsSubmitButtonClicked(false);
      toastSuccessMessages(toast.success, data?.message, "", 1000, 1000);
    }
  };

  const updateFormDataNewServiceUsers = catchAsync(async (req, res) => {
    console.log("🚀 _________________________________________:", req)
    const response = await updateRecords(UPDATE_SERVICE_USER_BY_ID_URL, req);
    updateEnquiryRecords("updateFormDataNewServiceUsers", response);
    console.log("🚀 ~ updateFormDataNewServiceUsers ~ response:", response)

    setIsSubmitButtonClicked(false);
    closeModal();
  });

  useEffect(() => {
    // if (Object.keys(updateUserDetails).length > 0) {
    setValues({
      active: true,
      serviceUserid: updateUserDetails?.modelId?._id || "",
      currSU: updateUserDetails?.modelId?.currSU,
      referenceId: updateUserDetails?.modelId?.referenceId || updateUserDetails?.modelId?.suEnquiries[0]?.referenceId || refID,
      name: updateUserDetails?.modelId?.suFirstMiddleName || updateUserDetails?.name || "",
      suFirstMiddleName: updateUserDetails?.modelId?.suFirstMiddleName || updateUserDetails?.name || "",
      suLastName: updateUserDetails?.modelId?.suLastName || "",
      suPreferredName: updateUserDetails?.modelId?.suPreferredName || "",
      suSsn: updateUserDetails?.modelId?.suSsn || "",
      suSex: updateUserDetails?.modelId?.suSex || "Male",
      suDOB: moment(updateUserDetails?.modelId?.suDOB).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
      suAge: updateUserDetails?.modelId?.suAge || "",
      suReferredBY: updateUserDetails?.modelId?.suReferredBY || "",
      suFamilyHead: updateUserDetails?.modelId?.suFamilyHead || "",
      suAddress1: updateUserDetails?.modelId?.suAddress1 || "",
      suAddress2: updateUserDetails?.modelId?.suAddress2 || "",
      suCity: updateUserDetails?.modelId?.suCity || "",
      suState: updateUserDetails?.modelId?.suState || "",
      suFirstVisitDate: moment(updateUserDetails?.modelId?.suFirstVisitDate).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
      suLastVisitDate: moment(updateUserDetails?.modelId?.suLastVisitDate).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
      providerName: updateUserDetails?.modelId?.providerName || updateUserDetails?.modelId?.suProvider[0]?.staffMemberName,
      suHomePhone: updateUserDetails?.modelId?.suHomePhone || "",
      suWorkPhone: updateUserDetails?.modelId?.suWorkPhone || "",
      suMobileHomeNo: updateUserDetails?.modelId?.suMobileHomeNo || "",
      suMobileWorkNo: updateUserDetails?.modelId?.suMobileWorkNo || "",
      suEmailHome: updateUserDetails?.modelId?.suEmailHome || updateUserDetails?.email || "",
      suEmailWork: updateUserDetails?.modelId?.suEmailWork || "",
      suPrefHomeNo: updateUserDetails?.modelId?.suPrefHomeNo || "",
      suPrefWorkNo: updateUserDetails?.modelId?.suPrefWorkNo || "",
      suEmergencyContact: updateUserDetails?.modelId?.suEmergencyContact || updateUserDetails?.phoneNumber || "",
      seMedicalAlert: updateUserDetails?.modelId?.seMedicalAlert || "pending",
      suZip: updateUserDetails?.modelId?.suZip || "",
      suNote: updateUserDetails?.modelId?.suNote || "",
      profile_video_url: profileVideoUrl || updateUserDetails?.profile_video_url
    });
  }, [updateUserDetails]);

  // useEffect(() => {
  //   setValues((prevValues) => ({
  //     ...prevValues,
  //     name: updateUserDetails?.modelId?.suFirstMiddleName || updateUserDetails?.name || "",
  //     email: updateUserDetails?.modelId?.suEmailHome || "",
  //   }));
  // });


  // console.log("status check karna hai:", updateUserDetails);
  const [formFields, setFormFields] = useState([
    { name: "currSU", labelText: "Status" },
    { name: "suFirstMiddleName", labelText: "First Name" },
    { name: "suLastName", labelText: "Last Name" },
    { name: "suPreferredName", labelText: "Preferred Name" },
    { name: "suSsn", labelText: "SSN" },
    { name: "suSex", labelText: "Gender" },
    { name: "suDOB", labelText: "Date of Birth" },
    { name: "suAge", labelText: "Age" },
    { name: "suReferredBY", labelText: "Referred by" },
    { name: "suFamilyHead", labelText: "Family Head" },
    { name: "suAddress1", labelText: "Address 1" },
    { name: "suAddress2", labelText: "Address 2" },
    { name: "suCity", labelText: "City" },
    { name: "suState", labelText: "State" },
    { name: "suFirstVisitDate", labelText: "First Visit Date" },
    { name: "suLastVisitDate", labelText: "Last Visit Date" },
    { name: "providerName", labelText: "Provider" },
    { name: "suHomePhone", labelText: "Home Phone No." },
    { name: "suWorkPhone", labelText: "Work Phone No." },
    { name: "suMobileHomeNo", labelText: "Mobile Home No." },
    { name: "suMobileWorkNo", labelText: "Mobile Work No." },
    { name: "suEmailHome", labelText: "Email Home" },
    { name: "suEmailWork", labelText: "Email Work" },
    { name: "suPrefHomeNo", labelText: "Pref. Home No." },
    { name: "suPrefWorkNo", labelText: "Pref. Work No." },
    { name: "suEmergencyContact", labelText: "Emergency Contact" },
    { name: "seMedicalAlert", labelText: "Funding by" },
    { name: "suZip", labelText: "Zip" },
    // { name: "suVideo", labelText: "Upload Video" },
    { name: "suNote", labelText: "Note" },
  ]);

  // Image change

  const handleFileChangeUploadDoc = async (e) => {
    try {
      setIsLoadingIcon(true);
      const selectedUploadDocFile = e.target?.files[0];
      const userId = updateUserDetails?._id || updateUserDetails?._id;
      console.log("🚀 ~ handleFileChangeUploadDoc ~ userId:", userId)
      await uploadFile({
        isUpload: false,
        updateFieldName: "userId",
        updateFieldId: userId,
        fileName: "file",
        file: selectedUploadDocFile,
        apiUrl: PUT_USERS_PROFILE_PICTURE_URL,
        successCallback: (result) => {
          handleFileChange(e);
          setIsLoadingIcon(false);
          fetchUserData();
          setIsFileUploadedToast(true);
          toastSuccessMessages(toast?.success, result?.message, "", 1000, 1000);
        },
      });
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfilePicture(event?.target?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleImageChange = (e) => {
  //   const file = e.target?.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       setProfilePicture(event?.target?.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleVideoUpload = async (e) => {
    setIsSubmitButtonClicked(true);
    try {
      const file = e.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;
      console.log("🚀 ~ handleVideoUpload ~ fileSize:", fileSize)
      // Validate file size (1MB = 1,048,576 bytes)
      if (fileSize > 1048576) {
        alert('File size exceeds 1MB');
        setVideoSizeError(true)
        setIsSubmitButtonClicked(false);
        return;
      }

      // Validate file type
      if (
        fileType === 'video/mp4' ||
        fileType === 'video/avi' ||
        fileType === 'video/mov' ||
        fileType === 'video/wmv' ||
        fileType === 'video/flv' ||
        fileType === 'video/webm' ||
        fileType === 'video/mkv' ||
        fileType === 'video/quicktime' // Add additional video formats as needed
      ) {
        const fileData = new FormData();
        fileData.append('attachements', file);
        const apiResponse = await fetch(PUT_USERS_PROFILE_VIDEO_URL, {
          method: "POST",
          body: fileData,
          headers: {
            Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
          },
        });
        const resultResponse = await apiResponse.json();
        // setUploadedFilePath(resultResponse?.data[0]?.fileNameInDirectory);
        setProfileVideoUrl(resultResponse?.data[0]?.fileNameInDirectory)
        setVideoSizeError(false)
        // console.log("File uploaded successfully:", resultResponse?.data[0]?.fileNameInDirectory);
        // console.log("uploadedFilePath uploadedFilePath uploadedFilePath:", uploadedFilePath);
      } else {
        // Unsupported file format
        alert('Unsupported file format');
      }
      setIsSubmitButtonClicked(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsSubmitButtonClicked(false);
    }
  };
  useEffect(() => {
    console.log("profileVideoUrl changed:", profileVideoUrl);

    setFieldValue("profile_video_url", profileVideoUrl);
    console.log("profile_video_url changed:", values?.profile_video_url);
  }, [profileVideoUrl, setFieldValue]);
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        className="ftcModalPopup editProfileModalPopup suProfileEdit"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="editBlockContent">
              <div className="row">
                <div className="col-md-12">
                  <div className="closeButton modalCloseButton">
                    <span onClick={closeModal}>
                      <img src={closeImage} alt={closeImage} />
                    </span>
                  </div>
                  <div className="text-center">
                    <label htmlFor="profilePicture" className="upload-icon">
                      {isLoadingIcon ? (
                        <div className="avatarAction">
                          <img src={loadingGif} alt={"Loading..."} />
                        </div>
                      ) : (
                        <div className="avatarAction">
                          <input
                            type="file"
                            id="profilePicture"
                            name="profilePic"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleFileChangeUploadDoc(e);
                            }}
                          />
                          <img
                            src={profilePicture}
                            alt={
                              updateUserDetails?.modelId?.suFirstMiddleName ? (
                                updateUserDetails?.modelId?.suFirstMiddleName
                              ) : (
                                updateUserDetails?.name
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                          <span className="uploadImage">
                            <img src={camera} alt="Camera" />
                          </span>
                        </div>
                      )}
                    </label>
                    <h3 className="userTitle">
                      {updateUserDetails?.modelId?.suFirstMiddleName ? (
                        updateUserDetails?.modelId?.suFirstMiddleName
                      ) : (
                        updateUserDetails?.name
                      )}&nbsp;
                      {updateUserDetails?.modelId?.suLastName}
                      <a
                        href={`mailto:${updateUserDetails?.modelId?.suEmailHome}`}
                        className="greyColor"
                      >
                        <small>{updateUserDetails?.modelId?.suEmailHome}</small>
                      </a>
                      <span>Ref ID: {
                        updateUserDetails?.modelId?.suEnquiries[0]?.referenceId ? (
                          updateUserDetails?.modelId?.suEnquiries[0]?.referenceId
                        ) : (
                          updateUserDetails?.modelId?.referenceId
                        )
                      }
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="row">
                  {formFields?.map(
                    (field, index) =>
                      field?.name !== "email" && (
                        <div
                          className={
                            field?.name === "suNote"
                              ? touched[field?.name] &&
                                errors[field?.name]
                                ? "col-sm-12 col-md-12 errorClass"
                                : "col-sm-12 col-md-12"
                              : field?.name === "suVideo"
                                ? touched[field?.name] &&
                                  errors[field?.name]
                                  ? "col-sm-12 col-md-8 errorClass"
                                  : "col-sm-12 col-md-8"
                                : touched[field?.name] &&
                                  errors[field?.name]
                                  ? "col-sm-6 col-md-4 errorClass"
                                  : "col-sm-6 col-md-4"
                          }
                          key={index}
                        >
                          {field?.name === "suNote" ? (
                            <TextArea
                              id={field?.name}
                              name={field?.name}
                              value={values[field?.name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelText={field?.labelText}
                              rows="3"
                            />
                          ) : field?.name === "currSU" ||
                            field?.name === "suSex" ? (
                            <>
                              <SelectBox
                                name={field?.name}
                                id={field?.name}
                                value={values[field?.name]}
                                onChange={handleChange}
                                options={
                                  field?.name === "suSex"
                                    ? genderOptions
                                    : field?.name === "currSU"
                                      ? currentStatus
                                      : null
                                }
                                labelText={field?.labelText}
                              />
                            </>
                          ) : field?.name === "suVideo" ? (
                            <>
                              <Input
                                type={
                                  field?.name === "suVideo" ? "file" : "text"
                                }
                                id={field?.name}
                                name={field?.name}
                                value={values[field?.name]}
                                accept={"video/*"}
                                // onChangeFun={(e) => handleProfilePicChange(e)}
                                labelText={field?.labelText}
                                className="form-control uploadProfileImage"
                              />
                            </>
                          ) : (
                            <Input
                              type={
                                field?.name === "suDOB" ||
                                  field?.name === "suFirstVisitDate" ||
                                  field?.name === "suLastVisitDate"
                                  ? "date"
                                  : "text"
                              }
                              id={field?.name}
                              name={field?.name}
                              value={values[field?.name]}
                              onChange={
                                field?.name === "suEmailHome" ? (
                                  (e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      email: e.target.value,
                                      suEmailHome: e.target.value
                                    }));
                                  }
                                ) : field?.name === "suFirstMiddleName" ? (
                                  (e) => {
                                    setValues((prevValues) => ({
                                      ...prevValues,
                                      name: e.target.value,
                                      suFirstMiddleName: e.target.value
                                    }));
                                  }
                                ) : (
                                  handleChange
                                )
                              }
                              onBlur={handleBlur}
                              labelText={field?.labelText}
                            />
                          )}

                          {touched[field?.name] &&
                            errors[field?.name] ? (
                            <div style={{ color: "red" }}>
                              {errors[field?.name]}
                            </div>
                          ) : null}
                        </div>
                      )
                  )}

                  <div className="col-md-12">
                    <div className="memoryBoxContent profileVideoContent">
                      <div className="imageBox text-center">
                        <label htmlFor="profilePicture" className="upload-icon">
                          <div className="avatarAction">
                            {
                              profileVideoUrl != null ? (
                                <video
                                  controls
                                  src={`${BASE_URL}${profileVideoUrl}`}
                                  style={{ cursor: "pointer" }}
                                  className={profileVideoUrl}
                                />
                              ) : values?.profile_video_url != "" ? (
                                <video
                                  controls
                                  src={`${BASE_URL}${values?.profile_video_url}`}
                                  style={{ cursor: "pointer" }}
                                />
                              ) : (
                                <>
                                  <p>
                                    <strong>
                                      {updateUserDetails?.name}&nbsp;
                                      {updateUserDetails?.modelId?.suLastName}'s
                                    </strong>{" "}
                                    Video is not available
                                  </p>
                                </>
                              )
                            }

                            {/* {uploadedFilePath !== '' && (
                              <>
                                {uploadedFilePath.match(/\.(jpeg|jpg|png)$/i) ? (
                                  <img
                                    src={`${BASE_URL}${uploadedFilePath}`}
                                    alt="Memory Box"
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <video
                                    controls
                                    src={`${BASE_URL}${uploadedFilePath}`}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </>
                            )} */}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        name="fileName"
                        id="fileName"
                        type="file"
                        accept="video/*,"
                        onChange={handleVideoUpload}
                      />
                      {
                        videoSizeError ? (
                          <>
                            <div className="validationError">
                              <div className="text-danger">Error: File size exceeds the 1MB limit. Please upload a smaller file.</div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div><p><small>Please upload a file that is 1MB or smaller.</small></p></div>
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal.Footer>
              {isSubmitButtonClicked ? (
                <Button
                  className="btn-sm"
                  variant="primary"
                  disabled
                >
                  Loading...
                </Button>
              ) : (
                <Button className="btn-sm" variant="primary" type="submit">
                  Submit
                </Button>
              )}
              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </form>
      </Modal>
      {isToast && <ToastContainer />}
      {isFileUploadedToast && <ToastContainer />}
      {isToastUpdateUserRecord && <ToastContainer />}
    </>
  );
}

export default ServiceUserProfileUpdate;
