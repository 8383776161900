import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import confirmPayroll from "../../../assets/images/icons/confirmPayroll.svg";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import {
  ADMIN_ID,
  POST_PAYROLL_LIST
} from "../../../env";
const AddPayroll = ({ onClose, selectedPayrollUsers }) => {



  const handleShowHideModal = () => {
    onClose((prevShow) => !prevShow);
  };
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  const [isToastAddPayroll, setIsToastAddPayroll] = useState(false);

  const [suFormData, setSuFormData] = useState(selectedPayrollUsers)

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddPayrollFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });


  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddPayroll(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal()
      }, 1000)
    } else if (
      method === "postAddPayrollFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddPayroll(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal()
      }, 1000)

    }
  };

  const postAddPayrollFormData = catchAsync(async (req, res) => {
    const response = await postRecords(POST_PAYROLL_LIST, values);
    processPostRecords("postAddPayrollFormData", response);
    setIsSubmitButtonClicked(false);
    console.log("🚀 ~ postAddPayrollFormData ~ values:", values)
    console.log("🚀 ~ postAddPayrollFormData ~ response:", response)
  });


  return (
    <>
      <Modal
        show={onClose}
        onHide={onClose}
        className="ftcModalPopup payrollConfirmation"
        size={"md"}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={confirmPayroll} alt={confirmPayroll} className="payrollIcon" />
              <p>Please confirm if you wish to finalize the payroll for the selected user(s).</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="primary" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-primary"
              onClick={onClose}
            >
              No
            </Button>
          </Modal.Footer>
        </form>
        <ToastContainer />
      </Modal>

    </>
  );
};

export default AddPayroll;