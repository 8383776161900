
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import Layout from "../../components/layouts/Layout";
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
// import AddPbsPlans from "./AddPbsPlans";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from 'html-react-parser';
import { ToastContainer, toast } from "react-toastify";
import password_eye_icon_circle from "../../assets/images/icons/password_eye_icon_circle.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import useColumnsWithPermission from "../../permissions/useColumnsWithPermission";
import "react-toastify/dist/ReactToastify.css";
import {
  BASE_URL,
  GET_MEDICINE_STOCK_URL,
  DELETE_MEDICINE_STOCK_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../helper/helper";
// import EditPbsPlans from "./EditPbsPlans";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
function MedicineList() {
  if (localStorage.getItem('medicineListDetail')) {
    localStorage.removeItem('medicineListDetail');
  }
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [userData, setUserData] = useState([]);
  const apiQuery = {
    skip: 0,
    limit: 10
  };


  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [medicationId, setMedicationIdData] = useState({
    medicationId: null,
  });
  const [pbsPlansDetail, setPbsPlansDetail] = useState();
  const [isPbsPlans, setIsPbsPlans] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  // Modal Popup
  // const [showAddPbsPlans, setShowAddPbsPlans] = useState(false);
  // const subModalAddPbsPlans = () => {
  //   setShowAddPbsPlans(!showAddPbsPlans);
  //   fetchAllRecords();
  // };

  const [showDeletePbsPlans, setShowDeletePbsPlans] = useState(false);
  const subModalDeletePbsPlans = (delMedicineId) => {
    setMedicationIdData({ medicationId: delMedicineId });
    setShowDeletePbsPlans(!showDeletePbsPlans);
    fetchAllRecords();
  };

  // const [showEditPbsPlans, setShowEditPbsPlans] = useState(false);
  // const subModalEditPbsPlans = (pbsItem) => {
  //   setPbsPlansDetail(pbsItem);
  //   setShowEditPbsPlans(!showEditPbsPlans);
  //   fetchAllRecords();
  // };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const actionRecentIncidentsData = data?.data?.result
      const medicineListsData = actionRecentIncidentsData?.map((item) => item);
      setUserData(medicineListsData);
      setFilterSearch(medicineListsData);
      setIsLoading(false);
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsPbsPlans(false);
      }, 2000);
    }
    // else {
    //   console.log({ data });
    //   toastErrorMessages(toast.error, data?.message, 1000);
    // }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const query = req ? req : apiQuery
    const response = await getRecords(GET_MEDICINE_STOCK_URL, query);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {

    if (!isLoading) {
      const apiQuery = {
        skip: 0,
        limit: 10000
      };
      fetchAllRecords(apiQuery)
    }
  }, [isLoading]);
  // Table Columns

  const baseColumns = [
    {
      name: "Medicine",
      selector: (row) => {
        return (
          <div>
            <p className="m-0">{row?.medicationName}</p>
          </div>
        );
      },
    },
    {
      name: "Description",
      selector: (row) => {
        return (
          <div className="pt-3 pb-3">
            <p className="m-0">{row?.description}</p>
          </div>
        );
      },
      width: "38%",
    },
    {
      name: "Strength",
      selector: (row) => {
        return (
          <div className="text-center">
            <p className="m-0">{row?.strength}</p>
          </div>
        );
      },
      width: "10%",
      center: true
    },
    {
      name: "Unit Type",
      selector: (row) => {
        return (
          <div className="text-center">
            <p className="m-0">{row?.unitType}</p>
          </div>
        );
      },
      width: "12%",
      center: true,
    },
    {
      name: "Medicine Type",
      selector: (row) => {
        return (
          <div className="text-center">
            <p className="m-0">{row?.medicineType}</p>
          </div>
        );
      },
      width: "10%",
      center: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns individualReviewsActions">

            {/* <span className="edit" onClick={() => handleEditViewPBSPlans(row, "/view-medicine-list")}>
              <img src={password_eye_icon_circle} alt="View" title="View" />
            </span> */}
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span className="edit" onClick={() => handleEditViewPBSPlans(row, "/edit-medicine-list")}>
                <img src={editBlock} alt="Edit" title="Edit" />
              </span>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeletePbsPlans(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      width: "10%",
      center: true,
      wrap: true,
    },
  ];

  const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT, permissions.DELETE]);

  // Search Filter
  useEffect(() => {
    const result = userData.filter((user) => {
      const filterMedicationName = user?.medicationName
      return filterMedicationName.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, userData]);

  // Delete PbsPlans records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (medicationId !== "") {
      const response = await deleteRecords(
        DELETE_MEDICINE_STOCK_URL,
        medicationId
      );

      setIsPbsPlans(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeletePbsPlans(!showDeletePbsPlans);
    } else {
      setShowDeletePbsPlans(!showDeletePbsPlans);
      setIsSubmitButtonClicked(false);
      setIsPbsPlans(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setIsPbsPlans(false);
    }, 2000);
  }, [isPbsPlans])

  const handleEditViewPBSPlans = (item, url) => {
    localStorage.setItem(
      "medicineListDetail",
      JSON.stringify(item)
    );
    navigate(url);
  };

  return (
    <>
      <Layout pageTitleText="Medicine List">
        <div className="rowClickUnset pbsPlanUpdated">
          <div className="mainContent">

            <div className="dataTableWrapper">
              <DataTable
                className="maxWidthTable minWidthTable"
                columns={columns}
                data={filterSearch}
                pagination
                paginationRowsPerPageOptions={[1, 25, 50, 100]}
                paginationPerPage={10}
                fixedHeader
                fixedHeaderScrollHeight="520px"
                highlightOnHover
                actions={
                  <>
                    <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                      <Link to="/add-medicine-list" className="btn btn-sm btn-primary">
                        Add New Medicine
                      </Link>
                    </ProtectedComponent>
                  </>
                }
                responsive={true}
                subHeader={true}
                subHeaderComponent={
                  <div className="searchContainer">
                    <div className="searchTextBox">
                      <input
                        type="text"
                        placeholder="Search by Medicine Name"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                }
                subHeaderAlign="left"
                subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                progressPending={isLoading}
                progressComponent={<ProgressComponent />}
              />
            </div>
          </div>
        </div>
      </Layout>


      {/* Delete PBS Plans */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeletePbsPlans}
          onHide={subModalDeletePbsPlans}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this item?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeletePbsPlans}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isPbsPlans && <ToastContainer />}
    </>
  );
}

export default MedicineList