import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../../components/general/ProgressComponent";
import restoreIcon from "../../../assets/images/icons/restoreIcon.svg";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL, UPDATE_STAFF_MEMBERS_URL } from "../../../env";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
    getRecords,
    isSuccess,
    filterColumns,
    deleteRecords,
    filterRowsByProperty,
    displayEnumProperty,
    filterArrayBySearchTerm,
    toastErrorMessages,
    formateDate,
    catchAsync,
    calculateHours,
    toastSuccessMessages,
} from "../../../helper/helper";

import ProtectedComponent from "../../../permissions/ProtectedComponent";
import { permissions } from "../../../permissions/roles";
import useColumnsWithPermission from "../../../permissions/useColumnsWithPermission";
import moment from "moment/moment";
import Layout from "../../../components/layouts/Layout";

function ArchievedStaffMembers() {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [userData, setUserData] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isArchievedStaffMembers, setIsArchievedStaffMembers] = useState(false);

    const [staffMemberData, setStaffMemberData] = useState({
        staffMemberid: "",
        active: false
    });

    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

    const apiQuery = {
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {
            critarion: { active: false },
        },
    };


    const [showDeleteArchievedStaffMembers, setShowDeleteArchievedStaffMembers] = useState(false);
    const subModalDeleteArchievedStaffMembers = (delStaffMemberId) => {
        setStaffMemberData({ staffMemberid: delStaffMemberId, active: true });
        setShowDeleteArchievedStaffMembers(!showDeleteArchievedStaffMembers);
        fetchAllRecords();
    };

    const processFetchRecords = (method, data) => {
        if (data?.status !== isSuccess) {
            // setIsToastStaffRecords(true);
            toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
        } else if (method === "get" && data?.status === isSuccess) {
            const staffMembers = data?.data?.staffMembers?.map((item) => item);
            const filteredRows = filterRowsByProperty(
                staffMembers,
                (row) => row?.user?.name
            );
            setUserData(filteredRows);
            setFilteredRows(filteredRows);
            setIsLoading(false);
        } else if (method === "delete" && data?.status === isSuccess) {
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
            setTimeout(() => {
                setIsArchievedStaffMembers(false);
            }, 2000);
        }
    };

    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
        console.log("🚀 ~ fetchAllRecords ~ response:", response)
        processFetchRecords("get", response);
    });

    useEffect(() => {
        fetchAllRecords();
    }, []);

    const handleSearch = (e) => {
        const searchVal = e?.target?.value;
        setSearch(searchVal);
        if (searchVal !== "") {
            const lowercaseSearch = searchVal?.toLowerCase();
            const filteredRecords = filterArrayBySearchTerm(
                userData,
                lowercaseSearch,
                (record) => record?.user?.name?.toLowerCase()
            );
            setFilteredRows(filteredRecords);
        } else {
            setFilteredRows(userData);
        }
    };

    const handleRowClickStaffMember = (e, row) => {
        e.preventDefault();
        navigate("/staff-profile", { state: { userId: row } });
    };

    const handleTableRowClick = (row) => {
        navigate("/staff-profile", { state: { userId: row } });
    };

    const baseColumns = [
        {
            name: "#",
            selector: (row, index) => (
                <span onClick={(e) => handleRowClickStaffMember(e, row)}>
                    {index + 1}
                </span>
            ),
            width: "5%",
            center: true,
        },
        {
            name: "Name",
            selector: (row) => {
                return (
                    <span
                        className="usersNameInfo"
                        onClick={(e) => handleRowClickStaffMember(e, row)}
                    >
                        <span className="userImage">
                            <img
                                src={`${BASE_URL}${row?.user?.profile_picture_url}`}
                                alt={row?.name}
                            />
                        </span>
                        <span className="userInfo">
                            <span className="nameBlock">{`${row?.user?.name}`}</span>
                        </span>
                    </span>
                );
            },
        },
        // {
        //     name: "Supervisions",
        //     selector: (row) => {
        //         const formatEdDate = formateDate(row?.supervision?.sprDueDate, "m/d");
        //         const currentDate = new Date();
        //         const dueDate = new Date(row?.supervision?.sprDueDate);
        //         const isDueThisMonth =
        //             currentDate?.getMonth() === dueDate.getMonth() &&
        //             currentDate?.getFullYear() === dueDate.getFullYear();
        //         const className = isDueThisMonth
        //             ? "tableText danger"
        //             : "tableText warning";
        //         return (
        //             <>
        //                 <span
        //                     className={className}

        //                 >Due Date<br />
        //                     {moment(row?.supervision?.sprDueDate).format("DD/MM/YYYY")}</span>
        //             </>
        //         )
        //     },
        //     width: "150px",
        // },
        {
            name: "Manager",
            selector: (row) => {
                return (
                    <span onClick={(e) => handleRowClickStaffMember(e, row)}>
                        {row?.managerId?.name}
                    </span>
                );
            },
            width: "150px",
        },
        {
            name: "Role",
            selector: (row) => {
                const userRole = displayEnumProperty("role", row?.user?.role);
                return (
                    <span onClick={(e) => handleRowClickStaffMember(e, row)}>
                        {userRole}
                    </span>
                );
            },
            width: "150px",
        },
        {
            name: "Email",
            selector: (row) => {
                return (
                    <a
                        href={`mailto:${row?.user?.email}`}
                        className="blackColor"

                    >
                        {row?.user?.email}
                    </a>
                );
            },
            width: "180px",
        },
        {
            name: "Contact No.",
            selector: (row) => {
                return (
                    <a
                        href={`tel:${row?.user?.phoneNumber}`}
                        className="blackColor"
                    >
                        {row?.user?.phoneNumber}
                    </a>
                );
            },
            width: "150px",
        },

        {
            name: "Action",
            selector: (row) => {
                return (
                    <div className="actionColumns">
                        <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                            <span
                                className="restore"
                                onClick={() => {
                                    subModalDeleteArchievedStaffMembers(row?._id);
                                }}
                            >
                                <img src={restoreIcon} alt="Delete" title="Delete" />
                            </span>
                        </ProtectedComponent>
                    </div>
                );
            },
            width: "10%",
            center: true,
        },
    ];
    const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT]);
    const filteredColumns = filterColumns(columns, (column) => column !== null);


    // Delete ArchievedStaffMembers records
    const deleteRecord = catchAsync(async (req, res) => {
        setIsSubmitButtonClicked(true);
        if (staffMemberData !== "") {
            const response = await deleteRecords(
                UPDATE_STAFF_MEMBERS_URL,
                staffMemberData
            );

            setIsArchievedStaffMembers(true);
            processFetchRecords("delete", response);
            fetchAllRecords();
            setIsSubmitButtonClicked(false);
            setShowDeleteArchievedStaffMembers(!showDeleteArchievedStaffMembers);
        } else {
            setShowDeleteArchievedStaffMembers(!showDeleteArchievedStaffMembers);
            setIsSubmitButtonClicked(false);
            setIsArchievedStaffMembers(true);
            toastErrorMessages(toast.error, "Unable to remove", "", 1000);
        }
    });
    useEffect(() => {
        setTimeout(() => {
            setIsArchievedStaffMembers(false);
        }, 2000);
    }, [isArchievedStaffMembers])





    return (
        <>
            <div className="mainContent">
                <div className="dataTableWrapper">
                    <DataTable
                        className="maxWidthTable"
                        columns={filteredColumns}
                        data={filteredRows}
                        pagination
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        paginationPerPage={10}
                        fixedHeader
                        fixedHeaderScrollHeight="520px"
                        highlightOnHover
                        actions={
                            <>
                                <Link to="/staff-records" className="btn btn-sm btn-primary">
                                    Active Staff Members
                                </Link>
                            </>
                        }
                        responsive={true}
                        subHeader={true}
                        subHeaderComponent={
                            <div className="searchContainer">
                                <div className="searchTextBox">
                                    <input
                                        type="text"
                                        placeholder="Type your search"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                        }
                        subHeaderAlign="left"
                        subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                        progressPending={isLoading}
                        progressComponent={<ProgressComponent />}
                        onRowClicked={handleTableRowClick}
                    />
                </div>
            </div>
            {/* Delete Rota Stock */}
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
                <Modal
                    show={showDeleteArchievedStaffMembers}
                    onHide={subModalDeleteArchievedStaffMembers}
                    className="ftcModalPopup confirmationPopup deletionConfirmation restoreConfirmation"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="editBlockContent text-center">
                            <img src={restoreIcon} alt={restoreIcon} />
                            <p>Are you sure you want to re-active this staff member?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {isSubmitButtonClicked ? (
                            <Button className="btn-sm" variant="success" disabled>
                                Loading...
                            </Button>
                        ) : (
                            <Button className="btn-sm" variant="success" onClick={deleteRecord}>
                                Yes
                            </Button>
                        )}

                        <Button
                            className="btn-sm"
                            variant="outline-secondary"
                            onClick={subModalDeleteArchievedStaffMembers}
                        >
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ProtectedComponent>
            {isArchievedStaffMembers && <ToastContainer />}

        </>
    );
}

export default ArchievedStaffMembers;