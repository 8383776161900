import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import eventDel from "../../assets/images/icons/eventDel.svg";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL, UPDATE_STAFF_MEMBERS_URL } from "../../env";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  getRecords,
  isSuccess,
  filterColumns,
  deleteRecords,
  filterRowsByProperty,
  displayEnumProperty,
  filterArrayBySearchTerm,
  toastErrorMessages,
  formateDate,
  catchAsync,
  calculateHours,
  toastSuccessMessages,
} from "../../helper/helper";

import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";
import useColumnsWithPermission from "../../permissions/useColumnsWithPermission";
import moment from "moment/moment";

function StaffRecordsGroup() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPbsPlans, setIsPbsPlans] = useState(false);

  const [staffMemberData, setStaffMemberData] = useState({
    staffMemberid: "",
    active: false
  });

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
    },
  };


  const [showDeletePbsPlans, setShowDeletePbsPlans] = useState(false);
  const subModalDeletePbsPlans = (delStaffMemberId) => {
    setStaffMemberData({ staffMemberid: delStaffMemberId, active: false });
    setShowDeletePbsPlans(!showDeletePbsPlans);
    fetchAllRecords();
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      // setIsToastStaffRecords(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const staffMembers = data?.data?.staffMembers?.map((item) => item);
      const filteredRows = filterRowsByProperty(
        staffMembers,
        (row) => row?.user?.name
      );
      setUserData(filteredRows);
      setFilteredRows(filteredRows);
      setIsLoading(false);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsPbsPlans(false);
      }, 2000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const handleSearch = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(
        userData,
        lowercaseSearch,
        (record) => record?.user?.name?.toLowerCase()
      );
      setFilteredRows(filteredRecords);
    } else {
      setFilteredRows(userData);
    }
  };

  const handleRowClickStaffMember = (e, row) => {
    e.preventDefault();
    navigate("/staff-profile", { state: { userId: row } });
  };

  const handleTableRowClick = (row) => {
    navigate("/staff-profile", { state: { userId: row } });
  };
  const baseColumns = [
    {
      name: "#",
      selector: (row, index) => (
        <span onClick={(e) => handleRowClickStaffMember(e, row)}>
          {index + 1}
        </span>
      ),
      width: "5%",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <span
            className="usersNameInfo"
            onClick={(e) => handleRowClickStaffMember(e, row)}
          >
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.user?.profile_picture_url}`}
                alt={row?.name}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.user?.name}`}</span>
            </span>
          </span>
        );
      },
    },
    {
      name: "Supervisions",
      selector: (row) => {
        const formatEdDate = formateDate(row?.supervision?.sprDueDate, "m/d");
        const currentDate = new Date();
        const dueDate = new Date(row?.supervision?.sprDueDate);
        const isDueThisMonth =
          currentDate?.getMonth() === dueDate.getMonth() &&
          currentDate?.getFullYear() === dueDate.getFullYear();
        const className = isDueThisMonth
          ? "tableText danger"
          : "tableText warning";
        return (
          <>
            <span
              className={className}
              onClick={(e) => handleRowClickStaffMember(e, row)}
            >Due Date<br />
              {moment(row?.supervision?.sprDueDate).format("DD/MM/YYYY")}</span>
          </>
        )
      },
      width: "150px",
    },
    {
      name: "Manager",
      selector: (row) => {
        return (
          <span onClick={(e) => handleRowClickStaffMember(e, row)}>
            {row?.managerId?.name}
          </span>
        );
      },
      width: "150px",
    },
    {
      name: "Role",
      selector: (row) => {
        const userRole = displayEnumProperty("role", row?.user?.role);
        return (
          <span onClick={(e) => handleRowClickStaffMember(e, row)}>
            {userRole}
          </span>
        );
      },
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => {
        return (
          <a
            href={`mailto:${row?.user?.email}`}
            className="blackColor"
            onClick={(e) => handleRowClickStaffMember(e, row)}
          >
            {row?.user?.email}
          </a>
        );
      },
      width: "180px",
    },
    {
      name: "Contact No.",
      selector: (row) => {
        return (
          <a
            href={`tel:${row?.user?.phoneNumber}`}
            className="blackColor"
            onClick={(e) => handleRowClickStaffMember(e, row)}
          >
            {row?.user?.phoneNumber}
          </a>
        );
      },
      width: "150px",
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns">
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeletePbsPlans(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      width: "10%",
      center: true,
    },
  ];
  const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT]);
  const filteredColumns = filterColumns(columns, (column) => column !== null);


  // Delete PbsPlans records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (staffMemberData !== "") {
      const response = await deleteRecords(
        UPDATE_STAFF_MEMBERS_URL,
        staffMemberData
      );

      setIsPbsPlans(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeletePbsPlans(!showDeletePbsPlans);
    } else {
      setShowDeletePbsPlans(!showDeletePbsPlans);
      setIsSubmitButtonClicked(false);
      setIsPbsPlans(true);
      toastErrorMessages(toast.error, "Unable to remove", "", 1000);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setIsPbsPlans(false);
    }, 2000);
  }, [isPbsPlans])

  return (
    <>
      <div className="mainContent staffRecordsGroup">
        <div className="dataTableWrapper">
          <DataTable
            className="maxWidthTable"
            columns={filteredColumns}
            data={filteredRows}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="520px"
            highlightOnHover
            actions={
              <>
                <ProtectedComponent requiredPermissions={[permissions.ADMIN]}>
                  <Link to="/archieved-staff-records" className="btn btn-sm btn-outline-primary">
                    Archived Staff Members
                  </Link>
                  {" "}
                </ProtectedComponent>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link to="/add-new-staff" className="btn btn-sm btn-primary">
                    Add New
                  </Link>
                </ProtectedComponent>
              </>
            }
            responsive={true}
            subHeader={true}
            subHeaderComponent={
              <div className="searchContainer">
                <div className="searchTextBox">
                  <input
                    type="text"
                    placeholder="Type your search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            }
            subHeaderAlign="left"
            subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
            onRowClicked={handleTableRowClick}
          />
        </div>
      </div>
      {/* Delete Rota Stock */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeletePbsPlans}
          onHide={subModalDeletePbsPlans}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this item?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeletePbsPlans}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isPbsPlans && <ToastContainer />}
    </>
  );
}

export default StaffRecordsGroup;
